import { createTheme } from '@material-ui/core/styles';

// Create your theme object
const theme = createTheme({
  white: '#FFFFFF',
  black: '#000000',
  workwaveBlue: '#002D5C',
  mediumBlue: '#055291',
  lightBlue: '#2a7abc',
  blueGreen: '#0f95a4',
  pestpacBlue: '#1E488A',
  serviceBlue: '#008AD1',
  servmanBlue: '#008AD1',
  routeManagerGreen: '#8CC82D',
  realgreenGreen: '#3FA529',
  gpsBlue: '#21C2E6',
  workwaveOrange: '#DE6426',
  ifsPurple: '#873E8D',
  darkGray: '#33383B',
  mediumDarkGray: '#4B5B69',
  mediumGray: '#5F6973',
  mediumLightGray: '#8d8d8d',
  lightGray: '#ECECEE',
  superlightGray: '#F6F9FC',
  borderLightGray: 'rgba(207, 215, 223, 0.25)',
  palette: {
    primary: {
      main: '#002D5C',
    },
    secondary: {
      main: '#2A7ABC',
    },
    darkBlue: {
      main: '#002D5C',
    },
    mediumBlue: {
      main: '#0380B6',
    },
    lightBlue: {
      main: '#63CDE5',
    },
    pink: {
      main: '#D10066',
    },
    backgroundLight: {
      main: '#F5F9FF',
    },
    backgroundDark: {
      main: '#002D5C',
    },
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#002D5C',
      secondary: '#4B5B69',
      white: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Poppins", "Arial", sans-serif',

    // Custom typography

    h1: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.2,
      margin: '0',
    },
    h2: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: 1.2,
      margin: '0',
      [createTheme().breakpoints.down('xs')]: {
        fontSize: '1.8rem',
      },
    },
    h3: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 1.2,
      margin: '0',
      [createTheme().breakpoints.down('xs')]: {
        fontSize: '1.6rem',
      },
    },
    h4: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 600,
    },
    h6: {
      fontFamily: '"Poppins", sans-serif',
      lineHeight: 1.2,
      fontWeight: 600,
    },
    body1: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: '1.15rem',
      lineHeight: 1.2,
      color: '#4B5B69',
      margin: '0',
    },
    body2: {
      fontFamily: '"Roboto", sans-serif',
      fontSize: '1rem',
      lineHeight: 1.2,
      color: '#4B5B69',
      margin: '0',
    },
    button: {
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: '1rem',
    },
    link: {
      fontFamily: '"Poppins", sans-serif !important',
      display: 'none !important',
    },
  
    subcategory: {
      fontFamily: '"Poppins", sans-serif !important',
      display: 'none !important',
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        // Variant Mapping
      },
    },
  },
  spacing: 8,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '8px',
        minHeight: '50px',
        fontSize: '1.2rem !important',
        [createTheme().breakpoints.down('xs')]: {
          fontSize: '1rem !important',
          minHeight: '40px',
        },
      },
    },
  },
});

export default theme;
