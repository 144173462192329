import React, { useState, createContext } from 'react';

const PardotContext = createContext();

export function PardotProvider({ children }) {
  const [utm, setUtm] = useState('');
  const [formModalOpen, setFormModalOpen] = useState(false);
  //handles state for email input in hero
  const [email, setEmail] = useState('');
  //handler for opening the form modal
  const handleModalClick = () => {
    setFormModalOpen(true);
  };

  return (
    <PardotContext.Provider
      value={{
        utm,
        setUtm,
        formModalOpen,
        setFormModalOpen,
        handleModalClick,
        email,
        setEmail,
      }}
    >
      {children}
    </PardotContext.Provider>
  );
}

export default PardotContext;
