import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { LocaleModal } from './LocaleModal';
import { LocalizedFooter } from './Footer/LocalizedFooter';
import { LocalizedNav } from './Nav/LocalizedNav';
import { GeneralPageWrapper } from './GeneralPageWrapper';
import { HeightWave } from '../Waves/HeightWave';

import 'typeface-poppins';
import theme from '../../theme';

import {
  faAddressBook,
  faAddressCard,
  faArrowRightArrowLeft,
  faArrowUpRightDots,
  faAward,
  faBallotCheck,
  faBarcode,
  faBarsProgress,
  faBlog,
  faBooks,
  faBoxDollar,
  faBrakeWarning,
  faBriefcase,
  faBrowser,
  faBuildingColumns,
  faBuildingMagnifyingGlass,
  faBuildingMemo,
  faBuildingShield,
  faBullseyeArrow,
  faCalculatorSimple,
  faCalendarCheck,
  faCalendarCircleUser,
  faCalendarClock,
  faCalendarDays,
  faCalendarStar,
  faCalendarUsers,
  faChalkboardUser,
  faChartDiagram,
  faChartLineUp,
  faChartMixed,
  faChartMixedUpCircleDollar,
  faChartPieSimpleCircleDollar,
  faChartSimple,
  faChartUser,
  faCircleDollar,
  faCircleDollarToSlot,
  faClipboardListCheck,
  faClipboardUser,
  faCodeCompare,
  faComments,
  faCommentsDollar,
  faCommentsQuestionCheck,
  faCreditCard,
  faCubes,
  faDisplayChartUpCircleDollar,
  faEnvelope,
  faEnvelopeOpenDollar,
  faEye,
  faFileChartColumn,
  faFileChartPie,
  faFileContract,
  faFileInvoiceDollar,
  faFileMagnifyingGlass,
  faFileShield,
  faFileSignature,
  faFileSpreadsheet,
  faFileZipper,
  faFilterCircleDollar,
  faFish,
  faGavel,
  faGlobe,
  faGlobePointer,
  faHandHoldingCircleDollar,
  faHandHoldingDollar,
  faHandHoldingHand,
  faHandsHoldingDollar,
  faHeadset,
  faHelmetSafety,
  faHourglassClock,
  faHouseLaptop,
  faLightbulbGear,
  faLightbulbMessage,
  faList,
  faListCheck,
  faLocationDot,
  faMagnifyingGlassDollar,
  faMessageSmile,
  faMicrochip,
  faMobile,
  faMobileButton,
  faMobileSignalOut,
  faMoneyCheckDollarPen,
  faMoneyCheckPen,
  faMoneySimpleFromBracket,
  faNetworkWired,
  faPeopleGroup,
  faPeopleRoof,
  faPhoneOffice,
  faQrcode,
  faQuoteRight,
  faRadar,
  faReplyClock,
  faRotateReverse,
  faSatelliteDish,
  faScissors,
  faScreenUsers,
  faServer,
  faShieldCheck,
  faShieldHalved,
  faTimer,
  faTrainSubwayTunnel,
  faTruck,
  faTruckField,
  faTruckMedical,
  faUserCheck,
  faUserClock,
  faUserHeadset,
  faUserMagnifyingGlass,
  faUserPlus,
  faUserTag,
  faUsers,
  faUsersBetweenLines,
  faUsersGear,
  faGear,
  faUsersMedical,
  faUtensils,
  faVacuum,
  faWebhook,
  faCircleCheck,
  faCircleQuestion,
  faSquareStar,
  faIndustry,
  faChartTreeMap,
  faCartShopping,
  faMagnifyingGlassChart,
  faMagnifyingGlass,
  faDisplayChartUp,
  faSlidersUp,
  faMicrochipAi,
  faTriangleExclamation,
  faGearComplex,
  faUserXmark,
  faGears,
  faStopwatch,
  faLightbulbOn,
  faToolbox,
  faThumbsUp,
  faCircleNodes,
  faFileCheck,
  faGaugeMax,
  faMagnifyingGlassWaveform,
  faFolderGear,
  faPiggyBank,
  faLink,
  faHandshake,
  faArrowsRepeat,
  faHexagonNodes,
  faBrainCircuit,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faSquareFacebook,
  faSquareTwitter,
  faSquareInstagram,
  faSquareYoutube,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
//stat icons imported with alias to avoid naming conflicts with other icons
import {
  faArrowUpRightDots as faStatArrowUpRightDots,
  faArrowRightArrowLeft as faStatArrowRightArrowLeft,
  faCircleDollar as faStatCircleDollar,
  faCalendarClock as faStatCalendarClock,
  faChartMixedUpCircleDollar as faStatChartMixedUpCircleDollar,
  faShieldCheck as faStatShieldCheck,
  faCircleCheck as faStatCircleCheck,
  faCalendarStar as faStatCalendarStar,
  faHourglassClock as faStatHourglassClock,
  faGlobe as faStatGlobe,
  faUserClock as faStatUserClock,
  faHandHoldingCircleDollar as faStatHandHoldingCircleDollar,
  faBuildingShield as faStatBuildingShield,
  faMoneySimpleFromBracket as faStatMoneySimpleFromBracket,
  faCircleDollarToSlot as faStatCircleDollarToSlot,
  faFish as faStatFish,
  faCheck,
  // faBuilding as faStatBuilding,
  faVacuum as faStatVacuum,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faAddressBook,
  faAddressCard,
  faArrowRightArrowLeft,
  faArrowUpRightDots,
  faAward,
  faBallotCheck,
  faBarcode,
  faBarsProgress,
  faBlog,
  faBooks,
  faBoxDollar,
  faBrakeWarning,
  faBriefcase,
  faBrowser,
  faBuildingColumns,
  faBuildingMagnifyingGlass,
  faBuildingMemo,
  faBuildingShield,
  faBullseyeArrow,
  faCalculatorSimple,
  faCalendarCheck,
  faCalendarCircleUser,
  faCalendarClock,
  faCalendarDays,
  faCalendarStar,
  faCalendarUsers,
  faChalkboardUser,
  faChartDiagram,
  faChartLineUp,
  faChartMixed,
  faChartMixedUpCircleDollar,
  faChartPieSimpleCircleDollar,
  faChartSimple,
  faChartUser,
  faCircleDollar,
  faCircleDollarToSlot,
  faClipboardListCheck,
  faClipboardUser,
  faCodeCompare,
  faComments,
  faCommentsDollar,
  faCommentsQuestionCheck,
  faCreditCard,
  faCubes,
  faDisplayChartUpCircleDollar,
  faEnvelope,
  faEnvelopeOpenDollar,
  faEye,
  faFileChartColumn,
  faFileChartPie,
  faFileContract,
  faFileInvoiceDollar,
  faFileMagnifyingGlass,
  faFileShield,
  faFileSignature,
  faFileSpreadsheet,
  faFileZipper,
  faFilterCircleDollar,
  faFish,
  faGavel,
  faGlobe,
  faGlobePointer,
  faHandHoldingCircleDollar,
  faHandHoldingDollar,
  faHandHoldingHand,
  faHandsHoldingDollar,
  faHeadset,
  faHelmetSafety,
  faHourglassClock,
  faHouseLaptop,
  faLightbulbGear,
  faLightbulbMessage,
  faList,
  faListCheck,
  faMagnifyingGlassDollar,
  faMessageSmile,
  faMicrochip,
  faMobile,
  faMobileButton,
  faMobileSignalOut,
  faMoneyCheckDollarPen,
  faMoneyCheckPen,
  faMoneySimpleFromBracket,
  faNetworkWired,
  faPeopleGroup,
  faPeopleRoof,
  faPhoneOffice,
  faQrcode,
  faQuoteRight,
  faRadar,
  faReplyClock,
  faRotateReverse,
  faSatelliteDish,
  faScissors,
  faScreenUsers,
  faServer,
  faShieldCheck,
  faShieldHalved,
  faTimer,
  faTrainSubwayTunnel,
  faTruck,
  faTruckField,
  faTruckMedical,
  faUserCheck,
  faUserClock,
  faUserHeadset,
  faUserMagnifyingGlass,
  faUserPlus,
  faUserTag,
  faUsers,
  faUsersBetweenLines,
  faUsersGear,
  faGear,
  faUsersMedical,
  faUtensils,
  faVacuum,
  faWebhook,
  faSquareFacebook,
  faSquareTwitter,
  faSquareInstagram,
  faSquareYoutube,
  faLinkedin,
  faQuoteRight,
  faBriefcase,
  faClipboardUser,
  faChartLineUp,
  faCircleCheck,
  faCircleQuestion,
  faSquareStar,
  faIndustry,
  faChartTreeMap,
  faCartShopping,
  faStatArrowRightArrowLeft,
  faStatArrowUpRightDots,
  faStatCircleDollar,
  faStatCalendarClock,
  faStatChartMixedUpCircleDollar,
  faStatCircleCheck,
  faStatShieldCheck,
  faStatCalendarStar,
  faStatHourglassClock,
  faStatGlobe,
  faStatUserClock,
  faStatHandHoldingCircleDollar,
  faStatBuildingShield,
  faStatMoneySimpleFromBracket,
  faStatCircleDollarToSlot,
  faStatFish,
  faCheck,
  faMagnifyingGlassChart,
  faMagnifyingGlass,
  faDisplayChartUp,
  faSlidersUp,
  faMicrochipAi,
  faTriangleExclamation,
  faStopwatch,
  faCircleNodes,
  faFileCheck,
  faGearComplex,
  faUserXmark,
  faGears,
  faLightbulbOn,
  faToolbox,
  faThumbsUp,
  faGaugeMax,
  faMagnifyingGlassWaveform,
  faFolderGear,
  // faStatBuilding,
  faStatVacuum,
  faPiggyBank,
  faLink,
  faHandshake,
  faArrowsRepeat,
  faHexagonNodes,
  faBrainCircuit,
  faLocationDot
);

export const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizedNav />
      <GeneralPageWrapper>
        {children}
        <LocalizedFooter />
        <HeightWave />
        <LocaleModal />
      </GeneralPageWrapper>
    </ThemeProvider>
  );
};
