import React, { useContext } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import {
  PromotionBar,
  ResourceBar,
  Navbar,
  StandaloneResourceLink,
  MobileNav,
  NavLink,
  MobileNavLink,
  StandaloneNavLink,
  MobileStandaloneNavLink,
  ResourceLink,
} from 'ww-nav-components';

import { Button, Grid } from '@material-ui/core';

import { useStyles } from './navStyles';

import PromotionContext from '../../../context/PromotionContext';
import FormModalContext from '../../../context/FormModalContext';
import { RegionSelect } from '../RegionSelect';

export const APACNav = () => {
  const { nav } = useStaticQuery(graphql`
    {
      nav: sanityApacNav {
        title
        promotionBar {
          _rawContent
        }
        resourceBar {
          wwLink
          mktplcLink
          salesMobileFaIcon
          salesNavHeader
          salesHeader
          resourceSalesLink {
            title
            type
            faIcon
            iconColor {
              hexValue
            }
            specialFaIcon {
              title
              name
              colors {
                color
              }
            }
            name
            description
            slug {
              current
            }
          }
          supportNavHeader
          supportMobileFaIcon
          supportHeader
          resourceSupportLink {
            title
            type
            faIcon
            iconColor {
              hexValue
            }
            specialFaIcon {
              title
              name
              colors {
                color
              }
            }
            name
            description
            slug {
              current
            }
            newWindow
          }
        }
        teamNavLogo {
          asset {
            gatsbyImageData(height: 50, width: 265)
          }
        }
        whyTEAMMobileFaIcon
        whyTEAMNavHeader
        whyTEAMLinks {
          title
          type
          faIcon
          iconColor {
            hexValue
          }
          specialFaIcon {
            title
            name
            colors {
              color
            }
          }
          name
          slug {
            current
          }
          internalLink
        }
        whyTEAMCalloutCtas {
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, height: 240, width: 310)
            }
          }
          _rawContent
          link
          internalLink
        }
        solutionsMobileFaIcon
        solutionsNavHeader
        solutionsLinks {
          title
          type
          faIcon
          iconColor {
            hexValue
          }
          specialFaIcon {
            title
            name
            colors {
              color
            }
          }
          name
          slug {
            current
          }
          internalLink
        }
        solutionsCalloutTitle
        solutionsCalloutFaIcon
        _rawSolutionsCalloutContent
        solutionsCalloutCtas {
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 650, height: 640)
            }
          }
          _rawContent
          link
        }
        softwareLinks {
          type
          teamSoftwareIcon {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 50, height: 50)
            }
          }
          mainName
          description
          slug {
            current
          }
          internalLink
        }
        softwareNavHeader
        softwareCalloutTitle
        softwareCalloutFaIcon
        _rawSoftwareCalloutContent
        softwareCalloutCtas {
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 650, height: 240)
            }
          }
          _rawContent
        }
        industryMobileFaIcon
        industryNavHeader
        industryLinks {
          title
          type
          faIcon
          iconColor {
            hexValue
          }
          specialFaIcon {
            title
            name
            colors {
              color
            }
          }
          name
          slug {
            current
          }
          internalLink
        }
        industryCalloutTitle
        industryCalloutFaIcon
        _rawIndustryCalloutContent
        pricingMobileFaIcon
        pricingLink {
          title
          slug {
            current
          }
          internalLink
        }
        resourcesMobileFaIcon
        resourcesNavHeader
        resourcesLinks {
          title
          type
          faIcon
          iconColor {
            hexValue
          }
          specialFaIcon {
            title
            name
            colors {
              color
            }
          }
          name
          slug {
            current
          }
          internalLink
        }
        resourcesCalloutTitle
        resourcesCalloutFaIcon
        _rawResourcesCalloutContent
        ctaText
      }
    }
  `);

  const classes = useStyles();
  const { open, setOpen } = useContext(PromotionContext);
  const { handleModalClick } = useContext(FormModalContext);

  const handleSalesClick = (e) => {
    e.preventDefault();
    navigate('/en-au/contact-us/');
  };

  const {
    promotionBar,
    resourceBar,
    whyTEAMLinks,
    whyTEAMNavHeader,
    whyTEAMCalloutCtas,
    whyTEAMMobileFaIcon,
    ctaText,
    teamNavLogo,
    solutionsNavHeader,
    solutionsMobileFaIcon,
    solutionsLinks,
    solutionsCalloutTitle,
    solutionsCalloutFaIcon,
    _rawSolutionsCalloutContent,
    softwareNavHeader,
    softwareMobileFaIcon,
    softwareLinks,
    softwareCalloutTitle,
    softwareCalloutFaIcon,
    _rawSoftwareCalloutContent,
    industryMobileFaIcon,
    industryNavHeader,
    industryLinks,
    industryCalloutTitle,
    industryCalloutFaIcon,
    _rawIndustryCalloutContent,
    pricingLink,
    pricingMobileFaIcon,
    resourcesMobileFaIcon,
    resourcesNavHeader,
    resourcesLinks,
    resourcesCalloutTitle,
    resourcesCalloutFaIcon,
    _rawResourcesCalloutContent,
  } = nav;

  return (
    <>
      {promotionBar?._rawContent.length ? (
        <PromotionBar
          promotion={promotionBar}
          open={open}
          setOpen={setOpen}
          barColor='#d10066'
          ucText
        />
      ) : null}

      <Grid item className={classes.resource}>
        <ResourceBar>
          <StandaloneResourceLink
            link='https://workwave.com/'
            title={resourceBar.wwLink}
          />
          <StandaloneResourceLink
            link='https://marketplace.workwave.com'
            title={resourceBar.mktplcLink}
          />

          <ResourceLink
            header={resourceBar.salesNavHeader}
            contentHeader={resourceBar.salesHeader}
            links={resourceBar.resourceSalesLink}
            handleModalClick={handleSalesClick}
            internalLink
          />
          <ResourceLink
            header={resourceBar.supportNavHeader}
            contentHeader={resourceBar.supportHeader}
            links={resourceBar.resourceSupportLink}
            handleModalClick={handleModalClick}
            internalLink
          />
          <RegionSelect />
        </ResourceBar>
      </Grid>

      <Navbar image={teamNavLogo.asset.gatsbyImageData} apac>
        {/* mobile nav responsive hide gets controlled in ww-nav-components */}
        <MobileNav ctaText={ctaText} handleModalClick={handleModalClick}>
          <MobileNavLink
            header={whyTEAMNavHeader}
            links={whyTEAMLinks}
            icon={whyTEAMMobileFaIcon}
          />
          <MobileNavLink
            header={solutionsNavHeader}
            links={solutionsLinks}
            icon={solutionsMobileFaIcon}
          />
          <MobileNavLink
            header={softwareNavHeader}
            links={softwareLinks}
            icon={softwareMobileFaIcon}
          />
          <MobileNavLink
            header={industryNavHeader}
            links={industryLinks}
            icon={industryMobileFaIcon}
          />
          <MobileStandaloneNavLink
            link={pricingLink.title}
            title={pricingLink.title}
            icon={pricingMobileFaIcon}
            internal
          />
          <MobileNavLink
            header={resourcesNavHeader}
            links={resourcesLinks}
            icon={resourcesMobileFaIcon}
          />
          {/* <MobileNavLink
            header={resourceBar.productNavHeader}
            links={resourceBar.productLinks}
            icon={resourceBar.productMobileFaIcon}
          /> */}
          <MobileNavLink
            header={resourceBar.salesNavHeader}
            links={resourceBar.resourceSalesLink}
            icon={resourceBar.salesMobileFaIcon}
          />
          <MobileNavLink
            header={resourceBar.supportNavHeader}
            links={resourceBar.resourceSupportLink}
            icon={resourceBar.supportMobileFaIcon}
          />

          <MobileStandaloneNavLink
            link='https://marketplace.workwave.com'
            title={nav.resourceBar.mktplcLink}
            icon='cart-shopping'
          />
          <RegionSelect mobile />
        </MobileNav>
        <Grid item className={classes.desktop}>
          <NavLink
            header={whyTEAMNavHeader}
            links={whyTEAMLinks}
            calloutCtas={whyTEAMCalloutCtas}
            // calloutIcon={featuresCalloutFaIcon}
            // calloutTitle={featuresCalloutTitle}
            // calloutContent={_rawFeaturesCalloutContent}
          />
          <NavLink
            header={solutionsNavHeader}
            links={solutionsLinks}
            calloutIcon={solutionsCalloutFaIcon}
            calloutTitle={solutionsCalloutTitle}
            calloutContent={_rawSolutionsCalloutContent}
            // calloutCtas={solutionsCalloutCtas}
          />
          <NavLink
            header={softwareNavHeader}
            links={softwareLinks}
            calloutIcon={softwareCalloutFaIcon}
            calloutTitle={softwareCalloutTitle}
            calloutContent={_rawSoftwareCalloutContent}
            // calloutCtas={solutionsCalloutCtas}
          />
          <NavLink
            header={industryNavHeader}
            links={industryLinks}
            // calloutIcon={industryCalloutFaIcon}
            // calloutTitle={industryCalloutTitle}
            // calloutContent={_rawIndustryCalloutContent}
          />
          <StandaloneNavLink
            link={pricingLink.slug.current}
            title={pricingLink.title}
            internal={pricingLink.internalLink}
          />

          <NavLink
            header={resourcesNavHeader}
            links={resourcesLinks}
            calloutIcon={resourcesCalloutFaIcon}
            calloutTitle={resourcesCalloutTitle}
            calloutContent={_rawResourcesCalloutContent}
          />
          <Button
            variant='contained'
            size='medium'
            //color='secondary'
            className={classes.cta}
            onClick={handleModalClick}
          >
            {ctaText}
          </Button>
          {/* <ContactFormModal
            open={formModalOpen} // Centralized modal state
            onClose={() => setFormModalOpen(false)}
          /> */}
        </Grid>
      </Navbar>
    </>
  );
};
