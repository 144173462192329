import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { BackgroundCircles } from '../BackgroundCircles';
import SectionPaddingContext from '../../context/SectionPaddingContext';

const useStyles = makeStyles((theme) => ({
  '@keyframes GradientBackground': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  sectionBlue: {
    position: 'relative',
    background: 'linear-gradient(45deg, #002D5C, #00618B, #002D5C)',
    backgroundSize: '600% 600%',
    animation: '$GradientBackground 14s ease infinite',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}));

export const SectionBlue = ({
  top = false,
  bottom = false,
  extraTop = false,
  extraBottom = false,
  additionalTop = 0,
  additionalBottom = 0,
  homeHero,
  zIndex,
  children,
  marginTop = '0px',
  marginBottom = '0px',
}) => {
  const classes = useStyles();

  const { paddingTop, paddingTop2, paddingBottom, paddingBottom2 } = useContext(
    SectionPaddingContext
  );

  const [windowWidth, setWindowWidth] = useState(null);

  // Check if window is defined and only run on client-side
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth); // Only set window width after mount
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  if (windowWidth === null) {
    return null; // Prevent rendering until windowWidth is set
  }

  const finalPaddingTop = extraTop
    ? paddingTop2
    : top
    ? paddingTop
    : 0;

  const finalPaddingBottom = extraBottom
    ? paddingBottom2
    : bottom
    ? paddingBottom
    : 0;

  return (
    <div
      className={`${classes.sectionBlue}`}
      style={{
        paddingTop: `${finalPaddingTop + additionalTop}px`,
        paddingBottom: `${finalPaddingBottom + additionalBottom}px`,
        marginTop,
        marginBottom,
        overflow: 'hidden',
        zIndex: zIndex ? zIndex : null,
      }}
    >
      <BackgroundCircles />
      {children}
    </div>
  );
};
