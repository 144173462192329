export const countryCodes = [
  { country: 'Afghanistan', browserLanguage: 'en-GB', value: 'en-gb' },
  { country: 'Albania', browserLanguage: 'sq-AL', value: 'en-gb' },
  { country: 'Algeria', browserLanguage: 'ar-DZ', value: 'en-gb' },
  { country: 'American Samoa', browserLanguage: 'en-AS', value: 'en-au' },
  { country: 'Andorra', browserLanguage: 'ca-AD', value: 'en-gb' },
  { country: 'Angola', browserLanguage: 'pt-AO', value: 'en-gb' },
  { country: 'Australia', browserLanguage: 'en-AU', value: 'en-au' },
  { country: 'Austria', browserLanguage: 'de-AT', value: 'en-gb' },
  { country: 'Bahrain', browserLanguage: 'ar-BH', value: 'en-gb' },
  { country: 'Bangladesh', browserLanguage: 'bn-BD', value: 'en-au' },
  { country: 'Belgium', browserLanguage: 'nl-BE', value: 'en-gb' },
  { country: 'Benin', browserLanguage: 'fr-BJ', value: 'en-gb' },
  { country: 'Bhutan', browserLanguage: 'dz-BT', value: 'en-au' },
  {
    country: 'Bosnia and Herzegovina',
    browserLanguage: 'bs-BA',
    value: 'en-gb',
  },
  { country: 'Botswana', browserLanguage: 'en-BW', value: 'en-gb' },
  { country: 'Brunei', browserLanguage: 'ms-BN', value: 'en-au' },
  { country: 'Bulgaria', browserLanguage: 'bg-BG', value: 'en-gb' },
  { country: 'Burkina Faso', browserLanguage: 'fr-BF', value: 'en-gb' },
  { country: 'Burundi', browserLanguage: 'fr-BI', value: 'en-gb' },
  { country: "Côte d'Ivoire", browserLanguage: 'fr-CI', value: 'en-gb' },
  { country: 'Cabo Verde', browserLanguage: 'pt-CV', value: 'en-gb' },
  { country: 'Cambodia', browserLanguage: 'km-KH', value: 'en-au' },
  { country: 'Cameroon', browserLanguage: 'fr-CM', value: 'en-gb' },
  {
    country: 'Central African Republic',
    browserLanguage: 'fr-CF',
    value: 'en-gb',
  },
  { country: 'Chad', browserLanguage: 'fr-TD', value: 'en-gb' },
  { country: 'China', browserLanguage: 'zh-CN', value: 'en-au' },
  { country: 'Comoros', browserLanguage: 'ar-KM', value: 'en-gb' },
  {
    country: 'Congo (Congo-Brazzaville)',
    browserLanguage: 'fr-CG',
    value: 'en-gb',
  },
  { country: 'Cook Islands', browserLanguage: 'en-CK', value: 'en-au' },
  { country: 'Croatia', browserLanguage: 'hr-HR', value: 'en-gb' },
  { country: 'Cyprus', browserLanguage: 'el-CY', value: 'en-gb' },
  { country: 'Czechia', browserLanguage: 'cs-CZ', value: 'en-gb' },
  {
    country: 'Democratic Republic of the Congo',
    browserLanguage: 'fr-CD',
    value: 'en-gb',
  },
  { country: 'Denmark', browserLanguage: 'da-DK', value: 'en-gb' },
  { country: 'Djibouti', browserLanguage: 'fr-DJ', value: 'en-gb' },
  { country: 'Egypt', browserLanguage: 'ar-EG', value: 'en-gb' },
  { country: 'Equatorial Guinea', browserLanguage: 'es-GQ', value: 'en-gb' },
  { country: 'Eritrea', browserLanguage: 'ti-ER', value: 'en-gb' },
  { country: 'Estonia', browserLanguage: 'et-EE', value: 'en-gb' },
  { country: 'Eswatini', browserLanguage: 'ss-SZ', value: 'en-gb' },
  { country: 'Ethiopia', browserLanguage: 'am-ET', value: 'en-gb' },
  { country: 'Fiji', browserLanguage: 'en-FJ', value: 'en-au' },
  { country: 'Faroe Islands', browserLanguage: 'fo-FO', value: 'en-gb' },
  { country: 'Finland', browserLanguage: 'fi-FI', value: 'en-gb' },
  { country: 'France', browserLanguage: 'fr-FR', value: 'en-gb' },
  { country: 'French Polynesia', browserLanguage: 'fr-PF', value: 'en-au' },
  { country: 'Gabon', browserLanguage: 'fr-GA', value: 'en-gb' },
  { country: 'Gambia', browserLanguage: 'en-GM', value: 'en-gb' },
  { country: 'Georgia', browserLanguage: 'ka-GE', value: 'en-gb' },
  { country: 'Germany', browserLanguage: 'de-DE', value: 'en-gb' },
  { country: 'Ghana', browserLanguage: 'en-GH', value: 'en-gb' },
  { country: 'Gibraltar', browserLanguage: 'en-GI', value: 'en-gb' },
  { country: 'Greece', browserLanguage: 'el-GR', value: 'en-gb' },
  { country: 'Guam', browserLanguage: 'en-GU', value: 'en-au' },
  { country: 'Guinea', browserLanguage: 'fr-GN', value: 'en-gb' },
  { country: 'Guinea-Bissau', browserLanguage: 'pt-GW', value: 'en-gb' },
  { country: 'Hungary', browserLanguage: 'hu-HU', value: 'en-gb' },
  { country: 'Iceland', browserLanguage: 'is-IS', value: 'en-gb' },
  { country: 'India', browserLanguage: 'hi-IN', value: 'en-au' },
  { country: 'Indonesia', browserLanguage: 'id-ID', value: 'en-au' },
  { country: 'Iran', browserLanguage: 'fa-IR', value: 'en-gb' },
  { country: 'Iraq', browserLanguage: 'ar-IQ', value: 'en-gb' },
  { country: 'Ireland', browserLanguage: 'en-IE', value: 'en-gb' },
  { country: 'Israel', browserLanguage: 'he-IL', value: 'en-gb' },
  { country: 'Italy', browserLanguage: 'it-IT', value: 'en-gb' },
  { country: 'Japan', browserLanguage: 'ja-JP', value: 'en-au' },
  { country: 'Jordan', browserLanguage: 'ar-JO', value: 'en-gb' },
  { country: 'Kenya', browserLanguage: 'en-KE', value: 'en-gb' },
  { country: 'Kiribati', browserLanguage: 'en-KI', value: 'en-au' },
  { country: 'Kuwait', browserLanguage: 'ar-KW', value: 'en-gb' },
  { country: 'Laos', browserLanguage: 'lo-LA', value: 'en-au' },
  { country: 'Latvia', browserLanguage: 'lv-LV', value: 'en-gb' },
  { country: 'Lebanon', browserLanguage: 'ar-LB', value: 'en-gb' },
  { country: 'Lesotho', browserLanguage: 'en-LS', value: 'en-gb' },
  { country: 'Liberia', browserLanguage: 'en-LR', value: 'en-gb' },
  { country: 'Libya', browserLanguage: 'ar-LY', value: 'en-gb' },
  { country: 'Liechtenstein', browserLanguage: 'de-LI', value: 'en-gb' },
  { country: 'Lithuania', browserLanguage: 'lt-LT', value: 'en-gb' },
  { country: 'Luxembourg', browserLanguage: 'fr-LU', value: 'en-gb' },
  { country: 'Macedonia', browserLanguage: 'mk-MK', value: 'en-gb' },
  { country: 'Madagascar', browserLanguage: 'mg-MG', value: 'en-gb' },
  { country: 'Malawi', browserLanguage: 'en-MW', value: 'en-gb' },
  { country: 'Malaysia', browserLanguage: 'ms-MY', value: 'en-au' },
  { country: 'Maldives', browserLanguage: 'dv-MV', value: 'en-au' },
  { country: 'Mali', browserLanguage: 'fr-ML', value: 'en-gb' },
  { country: 'Malta', browserLanguage: 'en-MT', value: 'en-gb' },
  { country: 'Marshall Islands', browserLanguage: 'en-MH', value: 'en-au' },
  { country: 'Mauritania', browserLanguage: 'ar-MR', value: 'en-gb' },
  { country: 'Mauritius', browserLanguage: 'en-MU', value: 'en-gb' },
  { country: 'Micronesia', browserLanguage: 'en-FM', value: 'en-au' },
  { country: 'Monaco', browserLanguage: 'fr-MC', value: 'en-gb' },
  { country: 'Mongolia', browserLanguage: 'mn-MN', value: 'en-au' },
  { country: 'Montenegro', browserLanguage: 'sr-ME', value: 'en-gb' },
  { country: 'Morocco', browserLanguage: 'ar-MA', value: 'en-gb' },
  { country: 'Mozambique', browserLanguage: 'pt-MZ', value: 'en-gb' },
  { country: 'Myanmar', browserLanguage: 'my-MM', value: 'en-au' },
  { country: 'Namibia', browserLanguage: 'en-NA', value: 'en-gb' },
  { country: 'Nauru', browserLanguage: 'en-NR', value: 'en-au' },
  { country: 'Nepal', browserLanguage: 'ne-NP', value: 'en-au' },
  { country: 'Netherlands', browserLanguage: 'nl-NL', value: 'en-gb' },
  { country: 'New Caledonia', browserLanguage: 'fr-NC', value: 'en-au' },
  { country: 'New Zealand', browserLanguage: 'en-NZ', value: 'en-au' },
  { country: 'Niger', browserLanguage: 'fr-NE', value: 'en-gb' },
  { country: 'Nigeria', browserLanguage: 'en-NG', value: 'en-gb' },
  { country: 'Niue', browserLanguage: 'en-NU', value: 'en-au' },
  { country: 'North Korea', browserLanguage: 'ko-KP', value: 'en-au' },
  { country: 'North Macedonia', browserLanguage: 'mk-MK', value: 'en-gb' },
  { country: 'Norway', browserLanguage: 'no-NO', value: 'en-gb' },
  {
    country: 'Northern Mariana Islands',
    browserLanguage: 'en-MP',
    value: 'en-au',
  },
  { country: 'Oman', browserLanguage: 'ar-OM', value: 'en-gb' },
  { country: 'Pakistan', browserLanguage: 'ur-PK', value: 'en-au' },
  { country: 'Palau', browserLanguage: 'en-PW', value: 'en-au' },
  { country: 'Palestine State', browserLanguage: 'ar-PS', value: 'en-gb' },
  { country: 'Papua New Guinea', browserLanguage: 'en-PG', value: 'en-au' },
  { country: 'Philippines', browserLanguage: 'tl-PH', value: 'en-au' },
  { country: 'Poland', browserLanguage: 'pl-PL', value: 'en-gb' },
  { country: 'Portugal', browserLanguage: 'pt-PT', value: 'en-gb' },
  { country: 'Qatar', browserLanguage: 'ar-QA', value: 'en-gb' },
  { country: 'Romania', browserLanguage: 'ro-RO', value: 'en-gb' },
  { country: 'Rwanda', browserLanguage: 'fr-RW', value: 'en-gb' },
  { country: 'Samoa', browserLanguage: 'en-WS', value: 'en-au' },
  { country: 'San Marino', browserLanguage: 'it-SM', value: 'en-gb' },
  {
    country: 'Sao Tome and Principe',
    browserLanguage: 'pt-ST',
    value: 'en-gb',
  },
  { country: 'Saudi Arabia', browserLanguage: 'ar-SA', value: 'en-gb' },
  { country: 'Senegal', browserLanguage: 'fr-SN', value: 'en-gb' },
  { country: 'Serbia', browserLanguage: 'sr-RS', value: 'en-gb' },
  { country: 'Seychelles', browserLanguage: 'en-SC', value: 'en-au' },
  { country: 'Sierra Leone', browserLanguage: 'en-SL', value: 'en-gb' },
  { country: 'Singapore', browserLanguage: 'en-SG', value: 'en-au' },
  { country: 'Slovakia', browserLanguage: 'sk-SK', value: 'en-gb' },
  { country: 'Slovenia', browserLanguage: 'sl-SI', value: 'en-gb' },
  { country: 'Solomon Islands', browserLanguage: 'en-SB', value: 'en-au' },
  { country: 'Somalia', browserLanguage: 'so-SO', value: 'en-gb' },
  { country: 'South Africa', browserLanguage: 'en-ZA', value: 'en-gb' },
  { country: 'South Korea', browserLanguage: 'ko-KR', value: 'en-au' },
  { country: 'South Sudan', browserLanguage: 'en-SS', value: 'en-gb' },
  { country: 'Spain', browserLanguage: 'es-ES', value: 'en-gb' },
  { country: 'Sri Lanka', browserLanguage: 'si-LK', value: 'en-au' },
  { country: 'Sudan', browserLanguage: 'ar-SD', value: 'en-gb' },
  { country: 'Sweden', browserLanguage: 'sv-SE', value: 'en-gb' },
  { country: 'Switzerland', browserLanguage: 'de-CH', value: 'en-gb' },
  { country: 'Syria', browserLanguage: 'ar-SY', value: 'en-gb' },
  { country: 'Tanzania', browserLanguage: 'sw-TZ', value: 'en-gb' },
  { country: 'Taiwan', browserLanguage: 'zh-TW', value: 'en-au' },
  { country: 'Thailand', browserLanguage: 'th-TH', value: 'en-au' },
  { country: 'Timor-Leste', browserLanguage: 'pt-TL', value: 'en-au' },
  { country: 'Togo', browserLanguage: 'fr-TG', value: 'en-gb' },
  { country: 'Tokelau', browserLanguage: 'en-TK', value: 'en-au' },
  { country: 'Tonga', browserLanguage: 'to-TO', value: 'en-au' },
  { country: 'Tunisia', browserLanguage: 'ar-TN', value: 'en-gb' },
  { country: 'Turkey', browserLanguage: 'tr-TR', value: 'en-gb' },
  { country: 'Turkmenistan', browserLanguage: 'tk-TM', value: 'en-us' },
  { country: 'Tuvalu', browserLanguage: 'en-TV', value: 'en-au' },
  { country: 'Uganda', browserLanguage: 'en-UG', value: 'en-gb' },
  { country: 'United Arab Emirates', browserLanguage: 'ar-AE', value: 'en-gb' },
  { country: 'United Kingdom', browserLanguage: 'en-GB', value: 'en-gb' },
  { country: 'Vanuatu', browserLanguage: 'en-VU', value: 'en-au' },
  { country: 'Vietnam', browserLanguage: 'vi-VN', value: 'en-au' },
  { country: 'Wallis and Futuna', browserLanguage: 'fr-WF', value: 'en-au' },
  { country: 'Western Sahara', browserLanguage: 'ar-EH', value: 'en-gb' },
  { country: 'Yemen', browserLanguage: 'ar-YE', value: 'en-gb' },
  { country: 'Zambia', browserLanguage: 'en-ZM', value: 'en-gb' },
  { country: 'Zimbabwe', browserLanguage: 'en-ZW', value: 'en-gb' },
];
