import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.white,
    color: theme.workwaveBlue,
    padding: '0 0',
    borderTop: `1.3px ${theme.lightGray} solid`,
    minHeight: '64px',
    display: 'flex',
    justifyContent: 'center',
  },

  ctaButton: {
    backgroundColor: '#2A7ABC',
    color: 'white',
    '&:hover': {
      backgroundColor: '#002D5C',
    },
  },
  cta: {
    fontSize: '20px',
    marginLeft: '10px',
    backgroundColor: '#2A7ABC',
    opacity: '.8',
    color: 'white',
    '&:hover': {
      opacity: 1,
      backgroundColor: '#2A7ABC',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '12px 20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  img: {
    height: '3rem',
    marginTop: '.5rem',
    [theme.breakpoints.down('xs')]: {
      height: '2.5rem',
    },
  },
  resource: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop: {
    display: 'contents',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
