import { navigate } from 'gatsby';
import { findLang } from './findLang';

export const localeRedirect = (
  lang,
  location,
  setRedirectModalOpen,
  setSelectedLang,
  autoRedirect,
  manualRedirect,
  setManualRedirect,
  setAppropriateLang,
  ipCountryCode
) => {
  //check if browser language is in countryCodes
  const language = findLang(lang, ipCountryCode);

  //normalize the data
  const locationLower = location.toLowerCase();
  const initialLanguageLower = language.toLowerCase();

  //set appropriate language
  setAppropriateLang(initialLanguageLower);

  //check if the browser language is the same as the initial language or if the browser language is en-us and the location is not en-au or en-gb
  const noRedirect =
    locationLower.includes(initialLanguageLower) ||
    (initialLanguageLower === 'en-us' &&
      !locationLower.includes('en-au') &&
      !locationLower.includes('en-gb'));
  //if the above evaluates true, do nothing
  if (noRedirect) return;

  //if the page is set to autoredirect(typically homepages), redirect immediately
  if (autoRedirect && !manualRedirect) {
    //redirect to appropriate homepage
    initialLanguageLower === 'en-us'
      ? navigate(`/`)
      : navigate(`/${initialLanguageLower}/`);
    setSelectedLang(initialLanguageLower);
  } else {
    //otherwise set the modal open
    setRedirectModalOpen(true);
    setManualRedirect(false);
  }
};
