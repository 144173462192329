import React, { useContext } from 'react';

import LocaleContext from '../../../context/LocaleContext';

import { Footer } from './Footer';
import { EMEAFooter } from './EMEAFooter';
import { APACFooter } from './APACFooter';

export const LocalizedFooter = () => {
  const { selectedLang } = useContext(LocaleContext);

  const localizeFooter = () => {
    switch (selectedLang) {
      case 'en-us':
        return <Footer leadooBot='uhKAKqM5' />;
      case 'en-gb':
        return <EMEAFooter leadooBot='LZ0AFD2E' />;
      case 'en-au':
        return <APACFooter leadooBot='aCcMtSF' />;
      default:
        return <Footer leadooBot='uhKAKqM5' />;
    }
  };

  return <>{localizeFooter()}</>;
};
