import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  footer: {
    color: theme.palette.common.white,
    overflowX: 'hidden',
    elevation: 0,
    boxShadow: 'none',
  },
  headerText: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    marginBottom: '2rem',
    textAlign: 'left',
  },
  linkText: {
    textDecoration: 'none',
    marginBottom: '15px',
    color: theme.palette.common.white,
    fontSize: 16,
    textAlign: 'left',
    opacity: '1',
    transition: 'opacity .25s ease-in-out',
    '&:hover': {
      opacity: '0.6',
    },

    [theme.breakpoints.down('lg')]: {
      minHeight: 'auto',
      minWidth: 'auto',
    },
  },
  linkTextAddress: {
    textDecoration: 'none',
    marginBottom: '15px',
    color: theme.palette.common.white,
    fontSize: 16,
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      minHeight: 'auto',
      minWidth: 'auto',
    },
  },
  linkTextSocial: {
    opacity: '1',
    maxWidth: '25px',
    transition: 'opacity .25s ease-in-out',
    '&:hover': {
      opacity: '0.6',
    },
  },
  footerLogo: {
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
  footerSocialContainer: {
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
  newsletterButton: {
    transition: 'all 0.3s',
    '&:hover': {
      opacity: '0.6',
    },
  },
}));
