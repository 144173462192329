import React, { useState, createContext, useRef, useEffect } from 'react';

const SectionPaddingContext = createContext();

// We created a context around the page that generates a padding value based on an SVG wave element height.
// We use that value to create even paddings around each page section depending on which wave/styles are needed.

export function SectionPaddingProvider({ children }) {
  const waveRef = useRef(null);
  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingTop2, setPaddingTop2] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);
  const [paddingBottom2, setPaddingBottom2] = useState(0);

  const updatePadding = () => {
    if (waveRef.current) {
      const waveHeight = waveRef.current.getBoundingClientRect().height;
      setPaddingTop(waveHeight);
      setPaddingTop2(waveHeight * 2);
      setPaddingBottom(waveHeight);
      setPaddingBottom2(waveHeight * 2);
    }
  };

  useEffect(() => {
    updatePadding();

    window.addEventListener('resize', updatePadding);

    return () => {
      window.removeEventListener('resize', updatePadding);
    };
  }, []);

  return (
    <SectionPaddingContext.Provider
      value={{
        waveRef,
        paddingBottom,
        paddingBottom2,
        paddingTop,
        paddingTop2,
      }}
    >
      {children}
    </SectionPaddingContext.Provider>
  );
}

export default SectionPaddingContext;
