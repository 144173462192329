import React, { useContext } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { Button, DialogTitle, Dialog } from '@material-ui/core';

import LocaleContext from '../../context/LocaleContext';
import { languages } from '../../data/languages';

export const LocaleModal = () => {
  // const classes = useStyles();
  const {
    redirectModalOpen,
    handleModalRedirect,
    handleModalStay,
    appropriateLang,
    selectedLang,
  } = useContext(LocaleContext);

  const replaceCode = (lang) => {
    const replacement = languages.find((l) => l.lang === lang);
    return (
      <>
        {replacement.name === 'NORAM' ? 'North America' : replacement.name}{' '}
        <ReactCountryFlag
          countryCode={replacement.flag}
          svg
          style={{ marginLeft: '5px' }}
        />
      </>
    );
  };

  return (
    <Dialog
      onClose={handleModalStay}
      aria-labelledby='simple-dialog-title'
      open={redirectModalOpen}
    >
      <DialogTitle id='simple-dialog-title'>
        Based on where you’re browsing from, we recommend visiting the TEAM
        Software site for your region.
      </DialogTitle>
      <Button variant='filled' color='primary' onClick={handleModalRedirect}>
        Take me back to {replaceCode(appropriateLang)}
      </Button>
      <Button variant='filled' color='secondary' onClick={handleModalStay}>
        No Thanks, go to {replaceCode(selectedLang)}
      </Button>
    </Dialog>
  );
};
