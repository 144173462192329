import React, { useContext } from 'react';
import ReactCountryFlag from 'react-country-flag';
import {
  makeStyles,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import LocaleContext from '../../context/LocaleContext';
import { languages } from '../../data/languages';

const useStyles = makeStyles((theme) => ({
  // When hovering over the FormControl, change the text and arrow color.
  formControl: {
    paddingRight: '0px',
    paddingBottom: 0,
    '&:hover $text': {
      color: 'rgb(47, 127, 193)',
      transition: 'all .5s ease',
    },
    '&:hover $selectIcon': {
      color: 'rgb(47, 127, 193)',
      transition: 'all .5s ease',
    },
  },
  // The default text style.
  text: {
    color: '#002D5C',
    fontSize: '14px',
    transition: 'all .5s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 600,
  },
  input: {
    padding: 0,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  // Override the default select padding.
  customSelect: {
    paddingRight: '16px !important',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  // Icon styles with a rotation transition and default color.
  selectIcon: {
    transition: 'all .5s ease',
    color: '#002D5C',
    '&.MuiSelect-iconOpen': {
      transform: 'rotate(180deg)',
    },
  },
  mobileContainer: {
    padding: '15px 0 15px 15px',
    borderBottom: `1px #FFFFFF solid`,
  },
  mobileText: {
    color: '#FFF',
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: theme.white,
  },
  summary: {
    background: theme.workwaveBlue,
    borderBottom: `1px ${theme.white} solid`,
    '& .MuiAccordionSummary-content': {
      margin: `15px 0`,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.workwaveBlue,
  },
  paper: {
    boxShadow: 'none',
  },
  productHeader: {
    background: theme.mediumBlue,
    padding: '17px 0 10px 0',
    color: theme.white,
  },
  subcategory: {
    fontWeight: 600,
    fontSize: '.8rem',
    marginBottom: '3px',
  },
  divider: {
    maxWidth: '90%',
    background: 'rgba(0, 0, 0, 0.3)',
  },
  subcategoryHeader: {},
}));

// Helper to render the flag icon with accessibility attributes.
const replaceCode = (lang) => {
  const replacement = languages.find((l) => l.lang === lang);
  return (
    <ReactCountryFlag
      countryCode={replacement.flag}
      svg
      style={{ margin: '0 6px 0 0' }}
      aria-label={`Flag for ${replacement.name}`}
      title={`Flag for ${replacement.name}`}
    />
  );
};

const MobileRegionSelect = () => {
  const classes = useStyles();
  const { selectedLang, handleManualChange } = useContext(LocaleContext);
  return (
    <div className={classes.root}>
      <Accordion elevation={0}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Grid container alignItems='center'>
            {replaceCode(selectedLang)}
            <Typography className={classes.heading}>
              {selectedLang === 'en-us'
                ? 'North America'
                : selectedLang === 'en-gb'
                ? 'EMEA'
                : 'APAC'}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction='column' justifyContent='flex-start'>
            {languages.map((lang, index) => (
              <div
                key={index}
                onClick={(e) =>
                  handleManualChange(e.target.getAttribute('value'))
                }
              >
                <MenuItem value={lang.lang} className={classes.text}>
                  <ReactCountryFlag
                    countryCode={lang.flag}
                    svg
                    style={{ margin: '0 5px' }}
                    aria-label={`Flag for ${lang.name}`}
                    title={`Flag for ${lang.name}`}
                  />
                  <span
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 400,
                      zIndex: 0,
                    }}
                    value={lang.lang}
                  >
                    {lang.name === 'NORAM' ? 'North America' : lang.name}
                  </span>
                </MenuItem>
              </div>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export const RegionSelect = ({ mobile }) => {
  const classes = useStyles();
  const { selectedLang, handleManualChange } = useContext(LocaleContext);

  return (
    <>
      {mobile ? (
        <MobileRegionSelect />
      ) : (
        <>
          {replaceCode(selectedLang)}
          <FormControl className={classes.formControl}>
            <Select
              labelId='demo-controlled-open-select-label'
              id='demo-controlled-open-select'
              value={selectedLang}
              onChange={(e) => handleManualChange(e.target.value)}
              className={classes.text}
              disableUnderline
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  className={`${props.className} ${classes.selectIcon}`}
                  style={{ width: '1rem' }} // color is handled by CSS via formControl hover
                />
              )}
              inputProps={{
                className: classes.input,
              }}
              classes={{
                select: classes.customSelect,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    marginTop: '10px',
                    borderRadius: '0 0 8px 8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                    minWidth: '134px',
                    marginLeft: '-22px',
                  },
                },
              }}
            >
              {languages.map((lang, index) => (
                <MenuItem
                  value={lang.lang}
                  key={index}
                  className={classes.text}
                >
                  {lang.name === 'NORAM' ? 'North America' : lang.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </>
  );
};

export default RegionSelect;
