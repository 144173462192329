exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-all-industries-js": () => import("./../../../src/templates/AllIndustries.js" /* webpackChunkName: "component---src-templates-all-industries-js" */),
  "component---src-templates-all-software-js": () => import("./../../../src/templates/AllSoftware.js" /* webpackChunkName: "component---src-templates-all-software-js" */),
  "component---src-templates-all-solutions-js": () => import("./../../../src/templates/AllSolutions.js" /* webpackChunkName: "component---src-templates-all-solutions-js" */),
  "component---src-templates-compare-js": () => import("./../../../src/templates/Compare.js" /* webpackChunkName: "component---src-templates-compare-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/ContactUs.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/Events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/Industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-newsletter-js": () => import("./../../../src/templates/Newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/Pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-software-js": () => import("./../../../src/templates/Software.js" /* webpackChunkName: "component---src-templates-software-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/Solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-special-update-js": () => import("./../../../src/templates/SpecialUpdate.js" /* webpackChunkName: "component---src-templates-special-update-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/Support.js" /* webpackChunkName: "component---src-templates-support-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/ThankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-why-team-js": () => import("./../../../src/templates/WhyTeam.js" /* webpackChunkName: "component---src-templates-why-team-js" */)
}

