import { countryCodes } from '../data/countryCodes';

export const findLang = (lang, ipCountry) => {
  //check if browser language is in countrys
  let language = '';
  if (lang === 'en') {
    language =
      countryCodes.find((c) => c.country.toLowerCase() === ipCountry)?.value ||
      'en-us';
  } else {
    language =
      countryCodes.find((c) => c.browserLanguage.toLowerCase() === lang)
        ?.value || 'en-us';
  }

  return language;
};
