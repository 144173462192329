import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SectionPaddingContext from '../../context/SectionPaddingContext';

const useStyles = makeStyles((theme) => ({
  outer: {
    width: '100%',
    position: 'fixed',
    opacity: 0,
    zIndex: 0,
    pointerEvents: 'none',
  },
  svgContainer: {
    display: 'flex',
    width: '100%',
    // Responsive height based on Material‑UI breakpoints:
    [theme.breakpoints.up('lg')]: {
      height: 150,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      height: 125,
    },
    [theme.breakpoints.down('md')]: {
      height: 100,
    },
    [theme.breakpoints.down('xs')]: {
      height: 80,
    },
  },

}));

export const HeightWave = () => {
  const classes = useStyles();
  const { waveRef } = useContext(SectionPaddingContext);

  return (
    <div ref={waveRef} className={classes.outer}>
      <div className={classes.svgContainer}>
        {/* Left SVG using the original HeightWave path data (transparent fill) */}
        <svg
          className={classes.leftSvg}
          viewBox="0 0 289 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.000247986 21.9918C88.753 116.434 152.393 146.63 288.7 145.992V0.000251778L0.000244141 0.000251777L0.000247986 21.9918Z"
            fill="transparent"
          />
          <path
            d="M3.47945e-07 1.99026C88.7527 96.4322 152.392 126.628 288.699 125.99V0L0 0.000125889L3.47945e-07 1.99026Z"
            fill="transparent"
          />
        </svg>

        {/* Right SVG using the original HeightWave path data (transparent fill) */}
        <svg
          className={classes.rightSvg}
          viewBox="0 0 1152 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            d="M0.000976562 149.992H1151.3V0.000244141L0.000976562 0.000244141V149.992Z"
            fill="transparent"
          />
          <path
            d="M0 129.442H1151.3V0L0 0.000206815V129.442Z"
            fill="transparent"
          />
        </svg>
      </div>
    </div>
  );
};
