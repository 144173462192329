import React, { useContext } from 'react';

import LocaleContext from '../../../context/LocaleContext';

import { Nav } from './Nav';
import { EMEANav } from './EMEANav';
import { APACNav } from './APACNav';

export const LocalizedNav = () => {
  const { selectedLang } = useContext(LocaleContext);

  const localizeNav = () => {
    switch (selectedLang) {
      case 'en-us':
        return <Nav />;
      case 'en-gb':
        return <EMEANav />;
      case 'en-au':
        return <APACNav />;
      default:
        return <Nav />;
    }
  };

  return <>{localizeNav()}</>;
};
