import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  parallaxBackground: {
    position: 'absolute',
    transform: 'rotate(-10.5deg)',
    bottom: '0',
    left: '0',
    opacity: '0.08', // Low opacity for subtle effect
    height: '200%',
    width: '200%',
    background:
      'radial-gradient(ellipse at center, #FFF 0%, #FFF 34%, transparent 34%)',
    backgroundSize: '40px 40px',
    backgroundRepeat: 'repeat',
    transition: 'background-position 0.1s ease-out', // Smooth transition for background movement

    // Adding the fade effect from right to left using mask-image
    maskImage: 'linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
    WebkitMaskImage:
      'linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))', // Webkit support for older browsers
    pointerEvents: 'none',
  },
});

export const BackgroundCircles = () => {
  const classes = useStyles();
  const [backgroundPosition, setBackgroundPosition] = useState({ x: 0, y: 0 });

  // Handle mouse movement to update background position
  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const xOffset = (clientX / window.innerWidth) * 0; // Subtle movement multiplier
    const yOffset = (clientY / window.innerHeight) * 0;

    // Slowly follow the mouse with the background
    setBackgroundPosition({ x: xOffset, y: yOffset });
  };

  useEffect(() => {
    // Attach mousemove event listener
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup the event listener when component unmounts
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <>
      <div
        className={classes.parallaxBackground}
        style={{
          backgroundPosition: `${backgroundPosition.x}px ${backgroundPosition.y}px`,
        }}
      ></div>
    </>
  );
};
