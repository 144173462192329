import React from 'react';
import { Layout } from './src/components/Layout/Layout';
// import { SearchProvider } from './src/context/SearchContext';
// import { TokenProvider } from './src/context/TokenContext';
// import { FormProvider } from './src/context/FormContext';
// import { IpProvider } from './src/context/IpContext';
// import './src/cookie-control/styles.css';
import { IndustrySearchProvider } from './src/context/IndustrySearchContext';

import { SectionPaddingProvider } from './src/context/SectionPaddingContext';
import { LocaleProvider } from './src/context/LocaleContext';
import { PromotionProvider } from './src/context/PromotionContext';
import { FormModalProvider } from './src/context/FormModalContext';
import { PardotProvider } from './src/context/PardotContext';

import { FeatureThumbnailProvider } from './src/context/FeatureThumbnailContext';

// import 'typeface-poppins';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

// export function wrapRootElement({ element }) {
// 	return (
// 		<IpProvider>
// 			<TokenProvider>
// 				<FormProvider>
// 					<SearchProvider>{element}</SearchProvider>
// 				</FormProvider>
// 			</TokenProvider>
// 		</IpProvider>
// 	);
// }

export function wrapRootElement({ element }) {
  return (
    <LocaleProvider>
      <PardotProvider>
        <FeatureThumbnailProvider>
          <IndustrySearchProvider>
            <FormModalProvider>
              <PromotionProvider>
                <SectionPaddingProvider>{element}</SectionPaddingProvider>
              </PromotionProvider>
            </FormModalProvider>
          </IndustrySearchProvider>
        </FeatureThumbnailProvider>
      </PardotProvider>
    </LocaleProvider>
  );
}
