import React from 'react';
import { useStaticQuery, graphql, Link, Script } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from '@reach/router';

import { AppBar, Typography, Container, Grid, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SectionBlue } from '../../Sections/SectionBlue';
import { FooterWaveOne } from '../../Waves/FooterWaveOne';
import { FooterWaveTwo } from '../../Waves/FooterWaveTwo';

import { useStyles } from './footerStyles';

export const Footer = ({ leadooBot }) => {
  const classes = useStyles();
  const location = useLocation();

  const { footer } = useStaticQuery(graphql`
    query {
      footer: sanityFooterContent {
        featureLinks {
          title
          slug {
            current
          }
          internalLink
        }
        whyTeamLinks {
          title
          slug {
            current
          }
          internalLink
        }
        industryLinks {
          title
          slug {
            current
          }
          internalLink
        }
        reachUsContent {
          title
          salesNumber
          supportNumber
          address
        }
        tsFooterLogo {
          asset {
            url
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 220)
          }
        }
      }
    }
  `);

  const med = useMediaQuery('(max-width: 960px)');

  return (
    <>
      <AppBar position='static' className={classes.footer}>
        <SectionBlue extraTop bottom>
          <Container>
            <Grid container direction='row'>
              <Grid
                container
                direction='row'
                justifyContent='space-evenly'
                alignItems='flex-start'
                style={{ textAlign: 'center' }}
              >
                {/* Why TEAM? Section */}
                <Grid item xs={6} md={3}>
                  <Grid
                    container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                  >
                    <Typography className={classes.headerText}>
                      Why TEAM?
                    </Typography>
                    {footer.whyTeamLinks.map((whyTeamLink) =>
                      whyTeamLink.internalLink ? (
                        <Link
                          key={whyTeamLink.slug.current}
                          to={`/${whyTeamLink.slug.current}`}
                          className={classes.linkText}
                        >
                          {whyTeamLink.title}
                        </Link>
                      ) : (
                        <a
                          key={whyTeamLink.slug.current}
                          href={whyTeamLink.slug.current}
                          className={classes.linkText}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {whyTeamLink.title}
                        </a>
                      )
                    )}
                  </Grid>
                </Grid>

                {/* Solutions Section */}
                <Grid item xs={6} md={3}>
                  <Grid
                    container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                  >
                    <Typography className={classes.headerText}>
                      Solutions
                    </Typography>
                    {footer.featureLinks.map((feature) => (
                      <Link
                        key={feature.slug.current}
                        to={`/${feature.slug.current}`}
                        className={classes.linkText}
                      >
                        {feature.title}
                      </Link>
                    ))}
                  </Grid>
                </Grid>

                {/* Industries Section */}
                <Grid item xs={6} md={3}>
                  <Grid
                    container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                  >
                    <Typography className={classes.headerText}>
                      Industries
                    </Typography>
                    {footer.industryLinks.map((industry) => (
                      <Link
                        key={industry.slug.current}
                        to={`/${industry.slug.current}`}
                        className={classes.linkText}
                      >
                        {industry.title}
                      </Link>
                    ))}
                  </Grid>
                </Grid>

                {/* Reach Us Section */}
                <Grid item xs={6} md={3}>
                  <Grid
                    container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    style={{ maxWidth: '350px' }}
                  >
                    <Typography className={classes.headerText}>
                      {footer.reachUsContent.title}
                    </Typography>
                    <a
                      href={`tel:${footer.reachUsContent.salesNumber
                        .replace('-', '')
                        .replace('(', '')
                        .replace(') ', '')
                        .replace(' ', '')}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.linkText}>
                        Sales: {footer.reachUsContent.salesNumber}
                      </Typography>
                    </a>
                    <a
                      href={`tel:${footer.reachUsContent.supportNumber
                        .replace('-', '')
                        .replace('(', '')
                        .replace(') ', '')
                        .replace(' ', '')}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.linkText}>
                        Support: {footer.reachUsContent.supportNumber}
                      </Typography>
                    </a>
                    <Typography
                      className={classes.linkTextAddress}
                      style={{ textAlign: 'left' }}
                    >
                      {footer.reachUsContent.address}
                      <br />
                      {location.pathname.includes('en-gb/')
                        ? 'United Kingdom'
                        : location.pathname.includes('en-au/')
                        ? 'Australia'
                        : 'USA'}
                    </Typography>
                    {!med && (
                      <a
                        href='/newsletter-sign-up/'
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          variant='contained'
                          size='large'
                          className={classes.newsletterButton}
                          style={{
                            background: '#FFF',
                            color: '#002D5C',
                            margin: '2rem 0',
                            lineHeight: '1.2',
                          }}
                        >
                          Newsletter Sign Up
                        </Button>
                      </a>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Newsletter Button for Mobile */}
              {med && (
                <Grid container item xs={12} justifyContent='center'>
                  <a
                    href='/newsletter-sign-up/'
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      variant='contained'
                      size='large'
                      className={classes.newsletterButton}
                      style={{
                        background: '#FFF',
                        color: '#002D5C',
                        margin: '4rem 0 0',
                        lineHeight: '1.2',
                      }}
                    >
                      Newsletter Sign Up
                    </Button>
                  </a>
                </Grid>
              )}

              {/* Footer Bottom Section */}
              <Grid
                container
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                spacing={2}
                style={{ marginTop: '4rem' }}
              >
                <Grid item xs={12} lg={2} className={classes.footerLogo}>
                  <a href='/'>
                    {footer.tsFooterLogo.asset.gatsbyImageData.images?.sources
                      .length ? (
                      <img
                        src={footer.tsFooterLogo.asset.url}
                        style={{
                          height: '2rem',
                          marginTop: '.5rem',
                        }}
                        alt='logo'
                      />
                    ) : (
                      <GatsbyImage
                        image={footer.tsFooterLogo.asset.gatsbyImageData}
                        alt='logo'
                      />
                    )}
                  </a>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={2}
                  className={classes.footerSocialContainer}
                >
                  <span className={classes.linkText}>
                    Copyright© {new Date().getFullYear()}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={2}
                  className={classes.footerSocialContainer}
                >
                  <a
                    href='https://www.workwave.com/privacy-policy'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classes.linkText}
                  >
                    Privacy Policy
                  </a>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={2}
                  className={classes.footerSocialContainer}
                >
                  <a
                    href='https://www.workwave.com/terms-of-use'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classes.linkText}
                  >
                    Terms of Use
                  </a>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={2}
                  className={classes.footerSocialContainer}
                >
                  <a
                    href='https://www.workwave.com/end-of-life-policy'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classes.linkText}
                  >
                    End of Life Policy
                  </a>
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={2}
                  className={classes.footerSocialContainer}
                >
                  <a
                    className={classes.linkTextSocial}
                    href='https://www.facebook.com/TEAMSoftwareInc'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title='facebook'
                      style={{
                        height: med ? 'auto' : '25px',
                        width: med ? 'auto' : '25px',
                        maxWidth: '25px',
                        color: '#FFFFFF',
                      }}
                      icon={['fab', 'square-facebook']}
                    />
                  </a>
                  <a
                    className={classes.linkTextSocial}
                    href='https://twitter.com/Team_Software'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title='twitter'
                      style={{
                        height: med ? 'auto' : '25px',
                        width: med ? 'auto' : '25px',
                        maxWidth: '25px',
                        color: '#FFFFFF',
                        marginLeft: med ? '3px' : '5px',
                      }}
                      icon={['fab', 'square-twitter']}
                    />
                  </a>
                  <a
                    className={classes.linkTextSocial}
                    href='https://www.instagram.com/team_software/'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title='instagram'
                      style={{
                        height: med ? 'auto' : '25px',
                        width: med ? 'auto' : '25px',
                        maxWidth: '25px',
                        color: '#FFFFFF',
                        marginLeft: med ? '2px' : '5px',
                      }}
                      icon={['fab', 'square-instagram']}
                    />
                  </a>
                  <a
                    className={classes.linkTextSocial}
                    href='https://www.youtube.com/user/TeamSoftware2012'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title='youtube'
                      style={{
                        height: med ? 'auto' : '25px',
                        width: med ? 'auto' : '25px',
                        maxWidth: '25px',
                        color: '#FFFFFF',
                        marginLeft: med ? '6px' : '9px',
                      }}
                      icon={['fab', 'square-youtube']}
                    />
                  </a>
                  <a
                    className={classes.linkTextSocial}
                    href='https://www.linkedin.com/company/team-software-inc/?trk=hb_tab_compy_id_107509'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title='linkedin'
                      style={{
                        height: med ? 'auto' : '25px',
                        width: med ? 'auto' : '25px',
                        maxWidth: '25px',
                        color: '#FFFFFF',
                        marginLeft: med ? '3px' : '5px',
                      }}
                      icon={['fab', 'linkedin']}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          {location.pathname === '/' ? <FooterWaveOne /> : <FooterWaveTwo />}
        </SectionBlue>
        <Script
          type='text/javascript'
          async
          src={`https://bot.leadoo.com/bot/chat.js?code=${leadooBot}#seamless`}
        />
      </AppBar>
    </>
  );
};
