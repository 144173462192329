import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  outer: {
    width: '100%',
    position: 'absolute',
    top: '0',
    transform: 'scaleX(-1) scaleY(-1)',
  },

  svgContainer: {
    display: 'flex',
    width: '100%',
    // Set container height by breakpoints:
    [theme.breakpoints.up('lg')]: {
      height: 300,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      height: 250,
    },
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
    [theme.breakpoints.down('xs')]: {
      height: 100,
    },
  },
  // Left SVG (natural size: 288×270; ratio = 288/270 ≈ 1.06667)
  leftSvg: {
    flexShrink: 0,
    height: '100%',
    width: 'auto',
  },
  // Right SVG (natural size: 289×270; ratio = 289/270 ≈ 1.07037)
  rightSvg: {
    flexShrink: 0,
    height: '100%',
    marginLeft: '-1px',
    width: 'auto',
  },
  // Middle SVG (fluid; natural size: 863×270)
  middleSvg: {
    flexGrow: 1,
    height: '100%',
    width: 'auto',
    marginRight: '-1px',
    marginLeft: '-1px',
  },
}));

export const FooterWaveTwo = () => {
  const classes = useStyles();

  return (
    <div className={classes.outer}>
      <div className={classes.svgContainer}>
        {/* Left SVG */}
        <svg
          className={classes.leftSvg}
          viewBox="0 0 288 270"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.0480325 237.639C84.0185 153.679 148.393 131.753 288 132.002V252.077H0.0480338C0.214635 251.91 -0.118414 237.806 0.0480325 237.639Z"
            fill="#63CDE5"
          />
          <path
            d="M0.0480325 250.592C84.0185 166.632 148.393 144.706 288 144.955V265.03H0.0480338C0.214635 264.863 -0.118414 250.758 0.0480325 250.592Z"
            fill="#367EB4"
          />
          <path
            d="M0.0480325 261.562C84.0185 177.602 148.393 155.676 288 155.925V276H0.0480338C0.214635 275.833 -0.118414 261.729 0.0480325 261.562Z"
            fill="white"
          />
        </svg>

        {/* Middle SVG */}
        <svg
          className={classes.middleSvg}
          viewBox="0 0 863 270"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path d="M863 132H0V252.077H863V132Z" fill="#63CDE5" />
          <path d="M863 145.5L0 144.953V265.03H863V145.5Z" fill="#367EB4" />
          <path d="M863 156.47L0 155.923V276H863V156.47Z" fill="white" />
        </svg>

        {/* Right SVG */}
        <svg
          className={classes.rightSvg}
          viewBox="0 0 289 270"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            d="M289 0V252H0V132C136.5 135.044 202.5 106.5 289 0Z"
            fill="#63CDE5"
          />
          <path
            d="M289 13V265H0V145.5C136.5 148.544 202.5 119.5 289 13Z"
            fill="#367EB4"
          />
          <path
            d="M289 24V276H0V156.5C136.5 159.544 202.5 130.5 289 24Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
