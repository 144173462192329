import React, { useState, createContext, useEffect } from 'react';
import { navigate } from 'gatsby';

import { localeRedirect } from '../utils/localeRedirect';

const LocaleContext = createContext();

const usePersistentState = (key, defaultValue) => {
  // Initialize state with the default value
  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    // Only run on the client (browser) side
    if (typeof window !== 'undefined') {
      try {
        // Get the saved value from localStorage
        const savedValue = localStorage.getItem(key);

        // If a value exists in localStorage, use it
        if (savedValue) {
          setState(JSON.parse(savedValue));
        } else {
          // Otherwise, store the default value in localStorage
          localStorage.setItem(key, JSON.stringify(defaultValue));
        }
      } catch (error) {
        console.error('Error reading from localStorage:', error);
      }
    }
  }, [key, defaultValue]); // Only re-run if key or defaultValue changes

  useEffect(() => {
    // Update localStorage whenever the state changes
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]); // Re-run when state or key changes

  return [state, setState];
};

export function LocaleProvider({ children }) {
  //use custom hook to persist state in localStorage
  const [selectedLang, setSelectedLang] = usePersistentState(
    'selectedLang',
    'en-us'
  );
  const [appropriateLang, setAppropriateLang] = useState('en-us');
  const [redirectModalOpen, setRedirectModalOpen] = useState(false);
  const [manualRedirect, setManualRedirect] = useState(false);

  //failsafe ip check
  const [ipData, setIpData] = useState(null);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch(
          `https://api.ipstack.com/check?access_key=${process.env.GATSBY_IP_API_KEY}`
        );
        const data = await response.json();
        setIpData(data);
      } catch (error) {
        console.error('Error fetching IP data:', error);
      }
    };

    fetchIP();
  }, []);

  const handleRedirect = (location, autoRedirect = false) => {
    //check for session storage key for previously declined redirect
    if (typeof window !== 'undefined') {
      const declined = !!window.sessionStorage.getItem('redirectDeclined');

      const configuredLang =
        window.navigator.userLanguage ||
        (window.navigator.languages &&
          window.navigator.languages.length &&
          window.navigator.languages[0]) ||
        window.navigator.language ||
        window.navigator.browserLanguage ||
        window.navigator.systemLanguage ||
        'en';

      //keeps you in the right spot on refresh if previously declined redirect
      if (declined) {
        //fix this to use includes instead of regex
        const setLocationDecline = location.includes('en-au')
          ? 'en-au'
          : location.includes('en-gb')
          ? 'en-gb'
          : 'en-us';
        setSelectedLang(setLocationDecline);
        return;
      }

      localeRedirect(
        configuredLang.toLowerCase(),
        location,
        setRedirectModalOpen,
        setSelectedLang,
        autoRedirect,
        manualRedirect,
        setManualRedirect,
        setAppropriateLang,
        ipData?.country_name.toLowerCase()
      );
    }
  };

  const handleModalRedirect = () => {
    setRedirectModalOpen(false);
    appropriateLang === 'en-us'
      ? navigate('/')
      : navigate(`/${appropriateLang}/`);
    setSelectedLang(appropriateLang);
  };

  const handleModalStay = () => {
    window.sessionStorage.setItem('redirectDeclined', true);
    setRedirectModalOpen(false);
  };

  const handleManualChange = (value) => {
    setSelectedLang(value);
    setManualRedirect(true);
    value === 'en-us' ? navigate('/') : navigate(`/${value}/`);
  };

  return (
    <LocaleContext.Provider
      value={{
        handleRedirect,
        redirectModalOpen,
        handleModalRedirect,
        handleModalStay,
        selectedLang,
        handleManualChange,
        appropriateLang,
        ipData,
        setIpData,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
}

export default LocaleContext;
