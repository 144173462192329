import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
  },
  visible: {
    opacity: 1,
  },
}));

export const GeneralPageWrapper = ({ children }) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);
  return (
    <div
      className={`${classes.contentWrapper} ${
        isVisible ? classes.visible : ''
      }`}
    >
      {children}
    </div>
  );
};
